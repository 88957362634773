<template>
  <div id="main-wrapper" class="show">
    <div class="authincation section-padding">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-xl-5 col-md-6">
            <b-alert
              show
              dismissible
              fade
              variant="success"
              class="d-flex align-items-center justify-content-between"
              >Please enter verification token from your device
            </b-alert>
            <auth-header :title="'Sign in to Qash'" />
            <div class="auth-form card">
              <div class="card-body">
                <p class="text-center mb-3">
                  Enter the verification code generated by your phone ending in
                  +xxx xxxxxxxx60.
                </p>
                <form @submit.prevent="onSubmit" class="row g-3">
                  <div class="col-12">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text py-3">
                          <i class="icofont-ui-touch-phone"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="verification code"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary btn-block">
                        Send
                      </button>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-check form-switch">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        <small
                          >Don't ask me for the code again for 30 days when I
                          use this computer.</small
                        >
                      </label>
                    </div>
                  </div>
                </form>
                <div class="new-account mt-3">
                  <p>
                    Don't get code?
                    <router-link class="text-primary" to="otp-1"
                      >Resend</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthHeader from "../../components/qCash/AuthHeader.vue";

export default {
  components: { AuthHeader },
  methods: {
    onSubmit() {
      this.$router.push("/");
    },
  },
};
</script>
