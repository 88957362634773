<template>
  <landing />
</template>

<script>
import Landing from "../components/Landing.vue";
export default {
  components: { Landing },
};
</script>
