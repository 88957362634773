<template>
  <landing>
    <div class="demo">
      <div class="container">
        <div class="row py-lg-5 justify-content-center">
          <div class="col-xl-7">
            <div class="section-heading text-center">
              <h2>Pages</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/intro" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/intro.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Intro</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/home.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Dashboard</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/trade" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/trade.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Trade</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/wallet" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/wallet.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Wallet</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/price" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/price.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Price</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/price-details" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/price-details.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Price Details</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/profile" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/profile.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Profile</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/signin" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/signin.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Sign in</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/signup" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/signup.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Sign up</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/reset" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/reset.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Reset</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/verify-email" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/verify-email.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Verify Email</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/otp-1" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/verify-phone.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Verify Phone</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/otp-2" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/verify-code.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Verify Code</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/lock" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/lock.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Lock</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/settings-profile" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/profile-edit.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Profile Edit</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/settings-application" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/application.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Application</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/settings-security" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/security.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Security</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/settings-activity" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/activity.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Activity</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/settings-privacy" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/privacy.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Privacy</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/settings-payment-method" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/payment.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Payment</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/settings-api" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/api.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>API</h4>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-sm-6">
            <div class="demo_img">
              <router-link to="/settings-fees" target="_blank">
                <div class="img-wrap">
                  <img
                    src="../assets/images/demo/fees.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </router-link>
              <h4>Fees</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </landing>
</template>

<script>
import Landing from "../components/Landing.vue";
export default {
  components: { Landing },
};
</script>
